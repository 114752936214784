<template>
  <p>{{ message }}</p>
</template>
<script>
import AuthService from "@/services/auth.service";
export default {
  data: function () {
    return {
      message: "Activating...",
    };
  },
  components: {},
  mounted() {
    AuthService.currentUser().then((user) => {
      this.currentUser = user;
    });

    AuthService.activate(this.$route.params.uid, this.$route.params.token).then(
      () => {
        this.message = "Activated successfully. Redirecting to login...";
        this.$router.push("/login");
      },
      (error) => {
        this.message =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>
<style lang="sass" scoped>
</style>